<template>
  <div>
      
        <!-- 轮播图 -->
      <div class="box">
        <div class="lbt">
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="item in carouselData" :key="item.id">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    <div class="neirong">


      <!-- 首页其他内容 -->
      <!-- 我们的公司 -->
      <div class="text">
        <div class="p1">
          <p><b>我们的公司</b></p>
        </div>
      </div>
      <div class="text">
        <div class="p2">
          <p>
            &emsp;&emsp;广州思迈信息科技有限公司是专注医疗信息化项目精细化管理的供应商，以“用思想服务医疗”的理念，将科学的管理方法融入医疗信息化项目管理，让管理流程更合理，从业人员更专业！公司以项目管理为核心，深度参与医疗信息化建设的规划和过程，帮助医疗机构建立信息系统保障管理体系，推动医疗信息化建设的规范化、标准化。
          </p>
        </div>
      </div>
      <!-- 我们的团队 -->
      <div class="text">
        <div class="p1">
          <p><b>我们的团队</b></p>
        </div>
      </div>
      <div class="text">
        <div class="p2">
          <p>
            &emsp;&emsp;二十年医院信息化项目建设经验；熟悉医院信息系统相关政策及行业标准；四十多家三级医院全院信息系统建设经验；一百多家医院信息化建设经验；熟悉医院信息系统建设内容。
          </p>
        </div>
      </div>


      <!-- 我们的目标 -->
      <div class="text">
        <div class="p1">
          <p><b>我们的目标</b></p>
        </div>
      </div>
      <div class="text">
        <div class="p2">
          <p>
            1.建立全方位医院智慧信息系统项目管理平台。<br />
            2.为医院的信息化建设提供数字化、标准化、智能化管理。<br />
            3.让决策管理层真正掌握信息化建设的真实信息。<br />
            4.严格按照项目启动、计划、执行、监控、收尾五大过程进行管理。<br />
            5.监督保障信息化项目建设，风险、质量、成本控制。<br />
            6.实现从项目申请、采购、建设、运维的信息化项目全流程管理。<br />
            7.为医疗信息管理者提供全方位的项目监控和大数据分析。<br />
            8.实现业务部门、信息部门、承建厂商的集中交互管理工作平台。<br />
            9.科学合理的工作安排，提高项目成员的工作效率和积极性。<br />
            10.提供智能的知识库管理，搜索引擎智能收集技术信息，为信息化建设提供标准和依据。
          </p>
        </div>
      </div>
      <!-- 我们的产品 -->
      <div class="text">
        <div class="p1">
          <p><b>产品的特色</b></p>
        </div>
      </div>
      <div class="box">
        <div class="box_1">
          <el-image :src="require('../assets/box1.png')"></el-image>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carouselData: [
        { url: require("../assets/lbt1.png"), title: "11", id: 1 },
        { url: require("../assets/lbt2.png"), title: "22", id: 2 },
        { url: require("../assets/lbt3.png"), title: "33", id: 3 },
       
      ],
    };
  },
};
</script>

<style >
/* .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 600px;
    margin: 0;
  } */
/* 轮播图 */


.el-carousel__item img {
  width: 100%;
  height: 600px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-carousel__container {
  height: 600px;
}

/* 文字 */
.neirong .text {
  display: flex;
  justify-content: center;
}
.neirong .text .p1 p {
  font-family:STHeiti;
  color: rgb(85, 85, 85);
  text-align: center;
  font-size: 30px;
}
.neirong .text .p2 p {
  font-family: Microsoft YaHei;
  font-size: 20px;
  line-height: 36px;
  width: 800px;
}
.neirong .text .p3 p {
  font-family: Microsoft YaHei;
  font-size: 20px;
  color: #1F497D;
  width: 1000px;
  line-height: 36px;
}

.neirong .text .p4 p {
    font-family: Microsoft YaHei;
  font-size: 20px;
 
  width: 1000px;
  line-height: 36px;

}
/* 段落间隔 */
.neirong .text .p1 {
  margin-bottom: 24px;
}
.neirong .text .p2 {
  margin-bottom: 18px;
}

/* 图片 */

.neirong .box {
  display: flex;
  justify-content: center;
}
.neirong .box .box_1 {
  width: 1200px;
}
</style>
